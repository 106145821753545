import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchDashboardData,
  fetchActivitiesData,
  fetchFocusedWorkData,
} from "../features/dashboard/dashboardSlice";
import { useNavigate } from "react-router-dom";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
} from "chart.js";
import { Bar, Line } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import {
  getChecklistWithItems,
  clearCheckListItems,
  getChecklistsByStatusAndPriority,
} from "../features/checklistItem/checkListItemSlice";
import { clearCheckLists } from "../features/checklist/checkListSlice";
import { toast } from "react-toastify";
import { setSequence } from "../features/checklistItem/checkListItemSlice";
import Confetti from "react-confetti";
import "./Dashboard.css"; // Import the CSS file for animations

import "reactflow/dist/style.css";
import {
  AiFillBook,
  AiFillTrophy,
  AiOutlineArrowLeft,
  AiOutlineHeatMap,
  AiOutlinePaperClip,
  AiOutlineSnippets,
} from "react-icons/ai";
import { GiTrophyCup } from "react-icons/gi";
import {
  FaArrowAltCircleLeft,
  FaArrowAltCircleRight,
  FaClipboardList,
} from "react-icons/fa";
import Spinner from "../components/Spinner";
import {
  listenForForegroundNotifications,
  requestNotificationPermission,
} from "../utils/notifications";

import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { fetchUserNotifications } from "../features/notifications/notificationSlice";
import { isMobile } from "react-device-detect";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  ChartDataLabels
);

const Dashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [currentSlide, setCurrentSlide] = useState(0);
  const [showConfetti, setShowConfetti] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [animateWidget, setAnimateWidget] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedPriority, setSelectedPriority] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const {
    notifications,
    isLoading: notificationsLoading,
    totalPages,
  } = useSelector((state) => state.notifications);

  const {
    isLoading,
    inProgressItems,
    deadlineItems,
    itemsToStartTomorrow,
    overdueItems,
    totalInProgress,
    totalDeadline,
    totalToStartTomorrow,
    totalOverdue,
    completedItems,
    totalCompleted,
    dueItems,
    activities,
    focusedWorkCount,
    completedItemsLastNDays, // Add completedItemsLastNDays to the state
    highestCompletedInADay, // Add highestCompletedInADay to the state
    completedNotInTimeItems, // Add completedNotInTimeItems to the state
    totalOverdueCompleted,
  } = useSelector((state) => state.dashboard);

  const userId = useSelector((state) => state.auth.user._id);
  useEffect(() => {
    if (userId && isMobile) {
      requestNotificationPermission(userId);
    }
  }, [userId]);

  useEffect(() => {
    const fetchData = async () => {
      await dispatch(fetchDashboardData())
        .unwrap()
        .then((user) => {})
        .catch((error) => {
          toast.error(error.message || "Something went wrong!", {
            autoClose: 2000,
          });
        });
      setDataLoaded(true);
    };
    fetchData();
  }, [dispatch]);

  useEffect(() => {
    const autoSlide = setInterval(() => {
      setCurrentSlide((prev) => (prev === 0 ? 1 : 0));
    }, 5000);
    return () => clearInterval(autoSlide);
  }, []);

  const isToday = (date) => {
    const today = new Date();
    const dateToCheck = new Date(date);
    return (
      today.getUTCFullYear() === dateToCheck.getUTCFullYear() &&
      today.getUTCMonth() === dateToCheck.getUTCMonth() &&
      today.getUTCDate() === dateToCheck.getUTCDate()
    );
  };

  useEffect(() => {
    if (dataLoaded && isToday(highestCompletedInADay.date)) {
      setShowConfetti(true);
      setAnimateWidget(true);
      setTimeout(() => {
        setShowConfetti(false);
        setAnimateWidget(false);
      }, 8000); // Hide confetti and stop animation after 8 seconds
    }
  }, [dataLoaded, highestCompletedInADay]);

  useEffect(() => {
    dispatch(fetchUserNotifications(currentPage))
      .unwrap()
      .then((user) => {})
      .catch((error) => {
        toast.error(error.message || "Something went wrong!", {
          autoClose: 2000,
        });
      });
  }, [dispatch, currentPage]);

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const chartData = {
    labels: [
      "In Progress",
      "Deadline",
      "To Start Tomorrow",
      "Overdue",
      "Completed",
    ],
    datasets: [
      {
        label: "", // Remove label
        data: [
          Math.round(totalInProgress),
          Math.round(totalDeadline),
          Math.round(totalToStartTomorrow),
          Math.round(totalOverdue),
          null, // Placeholder to align indexes, as "Completed" is stacked separately
        ],
        backgroundColor: [
          "#ffee93",
          "#ffb5a7",
          "#d4bbfc",
          "#fb6f92",
          "transparent",
        ],
        stack: "normal", // Ensure these remain unstacked
      },
      {
        label: "", // Remove label
        data: [
          null,
          null,
          null,
          null,
          Math.round(totalCompleted - totalOverdueCompleted),
        ], // First stacked part
        backgroundColor: "#8ac6d1",
        stack: "stacked-bar",
      },
      {
        label: "", // Remove label
        data: [null, null, null, null, Math.round(totalOverdueCompleted)], // Second stacked part
        backgroundColor: "#fb6f92",
        stack: "stacked-bar",
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    plugins: {
      legend: { display: false }, // Hide the legend
    },
    scales: {
      x: {
        stacked: false, // Ensures others are unstacked
      },
      y: {
        stacked: true, // Needed for the stacked "Completed" bar
      },
    },
    barThickness: 50, // Increase the bar width
  };

  const formatDate = (date) => {
    const today = new Date();
    const dateToFormat = new Date(date);
    if (
      today.getUTCFullYear() === dateToFormat.getUTCFullYear() &&
      today.getUTCMonth() === dateToFormat.getUTCMonth() &&
      today.getUTCDate() === dateToFormat.getUTCDate()
    ) {
      return "Today";
    }
    const options = { month: "short", day: "numeric" };
    return dateToFormat.toLocaleDateString("en-US", options);
  };

  const lineChartData = {
    labels: completedItemsLastNDays.map((item) => formatDate(item.date)),
    datasets: [
      {
        label: "Completed Items (Last 15 Days)",
        data: completedItemsLastNDays.map((item) => Math.round(item.count)),
        borderColor: "#9667e0",
        backgroundColor: "rgba(76, 175, 80, 0.2)",
        fill: false,
        borderWidth: 1.5,
        pointRadius: 1,
        borderCapStyle: "round", // Makes line ends rounded
        borderJoinStyle: "round", // Smooths line joins
        tension: 0.3, // Smoothens the curve
      },
    ],
  };

  const lineChartOptions = {
    responsive: true,
    scales: {
      x: {
        grid: {
          color: "#f7efed", // Light red grid lines
        },
      },
      y: {
        grid: {
          color: "#f7efed", // Light blue grid lines
        },
      },
    },
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Completed Items Over the Last 15 Days",
      },
      datalabels: {
        display: false, // Disable data labels on the line chart
      },
    },
  };

  const handleShowAllChecklists = async () => {
    // navigate("/checklists");

    if (selectedStatus || selectedPriority) {
      dispatch(clearCheckLists());
      dispatch(clearCheckListItems());

      try {
        const response = await dispatch(
          getChecklistsByStatusAndPriority({
            status: selectedStatus,
            priority: selectedPriority,
          })
        ).unwrap();
        navigate("/checklists", {
          state: { checklistWithItems: response, fromDashboard: true },
        });
      } catch (error) {
        console.error("Failed to fetch checklist with items:", error);
        toast.error(error || "Failed to fetch checklist with items");
      }
    } else {
      navigate("/checklists");
    }
  };

  const handleGoalMapAdd = () => {
    navigate("/goal-maps");
  };

  const handleShowDetails = async (items, title) => {
    dispatch(clearCheckLists());
    dispatch(clearCheckListItems());

    const checklistsWithItems = items.map((item) => ({
      _id: item._id,
      items: item.items.map((subItem) => ({ _id: subItem._id })),
    }));

    try {
      const response = await dispatch(
        getChecklistWithItems(checklistsWithItems)
      ).unwrap();
      navigate("/checklists", {
        state: { checklistWithItems: response, title, fromDashboard: true },
      });
    } catch (error) {
      console.error("Failed to fetch checklist with items:", error);
    }
  };

  const handleShowFocusedWork = async () => {
    dispatch(clearCheckLists());
    dispatch(clearCheckListItems());

    try {
      const response = await dispatch(fetchFocusedWorkData()).unwrap();
      navigate("/checklists", {
        state: {
          checklistWithItems: response,
          fromDashboard: true,
          focusedWork: true,
          title: "Today's Focus",
        },
      });
    } catch (error) {
      console.error("Failed to fetch checklist with items:", error);
      toast.error(error.message || "Failed to fetch checklist with items");
    }
  };

  const handlePrevSlide = () => {
    setCurrentSlide((prev) => (prev === 0 ? 1 : 0));
  };

  const handleNextSlide = () => {
    setCurrentSlide((prev) => (prev === 1 ? 0 : 1));
  };

  const handleStatusChange = (event) => {
    const status = event.target.value;
    setSelectedStatus(status);
  };

  const handlePriorityChange = (event) => {
    const priority = event.target.value;
    setSelectedPriority(priority);
  };

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <div className="min-h-screen bg-gray-100 text-gray-600">
      {showConfetti && <Confetti />}
      {/* Navbar */}
      <nav className="bg-white shadow">
        <div className="container mx-auto px-4 py-3 flex flex-col sm:flex-row justify-between items-center">
          <h1 className="text-xl font-bold text-gray-800 mb-2 sm:mb-0">
            Checklist Dashboard
          </h1>
          <div className="flex flex-col sm:flex-row space-y-2 sm:space-y-0 sm:space-x-4 items-center">
            <select
              value={selectedStatus}
              onChange={handleStatusChange}
              className="bg-white border border-gray-300 rounded px-4 py-2"
            >
              <option value="">Select Status</option>
              <option value="pending">Pending</option>
              <option value="completed">Completed</option>
              <option value="in progress">In Progress</option>
              <option value="on hold">On Hold</option>
              <option value="blocked">Blocked</option>
            </select>
            <select
              value={selectedPriority}
              onChange={handlePriorityChange}
              className="bg-white border border-gray-300 rounded px-4 py-2"
            >
              <option value="">Select Priority</option>
              <option value="low">Low</option>
              <option value="medium">Medium</option>
              <option value="high">High</option>
            </select>
            <button
              className="flex items-center gap-2  bg-purple-500 text-white px-4 py-2 rounded"
              onClick={handleShowAllChecklists}
            >
              <FaClipboardList />
              Show Checklists
            </button>
            <button
              className="flex items-center gap-2  bg-emerald-600 text-white px-4 py-2 rounded"
              onClick={handleGoalMapAdd}
            >
              <AiOutlineHeatMap /> Manage Goal Maps
            </button>
          </div>
        </div>
      </nav>

      {/* Dashboard Content */}
      <div className="container mx-auto px-4 py-6">
        {/* Stats Section */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 mb-6">
          <div
            className="bg-white p-4 rounded shadow text-center cursor-pointer"
            onClick={handleShowFocusedWork}
          >
            <h2 className="text-lg font-semibold">Today's Focus</h2>
            <p className="text-2xl font-bold">
              {focusedWorkCount.completedTodayCount || 0}/
              {focusedWorkCount.focusedWorkTodayCount || 0}
            </p>
          </div>
          <div
            className={`bg-white p-4 rounded shadow text-center ${
              animateWidget ? "animate-tada" : ""
            }`}
          >
            <h2 className="text-lg font-semibold">
              Highest No. of Items Completed in a Day
            </h2>
            <p className="flex items-center justify-center gap-2 text-2xl font-bold">
              {isToday(highestCompletedInADay.date) && (
                <div className="text-yellow-500">
                  <GiTrophyCup />
                </div>
              )}
              <div className="w-2/3">{highestCompletedInADay.count}</div>

              {isToday(highestCompletedInADay.date) && (
                <div className="text-yellow-500">
                  <GiTrophyCup />
                </div>
              )}
            </p>
            <p className="text-sm text-gray-500">
              {highestCompletedInADay.date
                ? formatDate(highestCompletedInADay.date)
                : "N/A"}
            </p>
          </div>
          <div
            className="bg-white p-4 rounded shadow text-center cursor-pointer"
            onClick={() =>
              handleShowDetails(inProgressItems, "In Progress Items")
            }
          >
            <h2 className="text-lg font-semibold">In Progress Items</h2>
            <p className="text-2xl font-bold">{totalInProgress}</p>
          </div>
          <div
            className="bg-white p-4 rounded shadow text-center cursor-pointer"
            onClick={() => handleShowDetails(deadlineItems, "Deadline Items")}
          >
            <h2 className="text-lg font-semibold">Deadline Items</h2>
            <p className="text-2xl font-bold ">{totalDeadline}</p>
          </div>
          <div
            className="bg-white p-4 rounded shadow text-center cursor-pointer"
            onClick={() =>
              handleShowDetails(itemsToStartTomorrow, "Items to Start Tomorrow")
            }
          >
            <h2 className="text-lg font-semibold">Items to Start Tomorrow</h2>
            <p className="text-2xl font-bold ">{totalToStartTomorrow}</p>
          </div>
          <div
            className="bg-white p-4 rounded shadow text-center cursor-pointer"
            onClick={() => handleShowDetails(overdueItems, "Overdue Items")}
          >
            <h2 className="text-lg font-semibold">Overdue Items</h2>
            <p className="text-2xl font-bold text-red">{totalOverdue}</p>
          </div>
          <div
            className="bg-white p-4 rounded shadow text-center cursor-pointer"
            onClick={() =>
              handleShowDetails(completedNotInTimeItems, "Overdue(Completed)")
            }
          >
            <h2 className="text-lg font-semibold">Overdue(Completed)</h2>
            <p className="text-2xl font-bold text-red">
              {totalOverdueCompleted}
            </p>
          </div>
          <div
            className="bg-white p-4 rounded shadow text-center cursor-pointer"
            onClick={() => handleShowDetails(completedItems, "Completed Items")}
          >
            <h2 className="text-lg font-semibold">Completed Items</h2>
            <p className="text-2xl font-bold ">{totalCompleted}</p>
          </div>
        </div>

        {/* Main Section */}
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-4">
          {/* Chart Section */}
          <div className="col-span-1 lg:col-span-2 bg-white p-6 rounded shadow">
            <div className="relative overflow-hidden h-80">
              {" "}
              {/* Increased height from h-64 to h-80 */}
              <div
                className={`absolute inset-0 transition-transform transform ${
                  currentSlide === 0 ? "translate-x-0" : "-translate-x-full"
                }`}
                style={{ width: "100%", height: "100%" }}
              >
                <h2 className="text-lg font-semibold mb-4">Completion Rate</h2>
                <div className="flex justify-center items-center h-full bg-gray-50 border border-dashed border-gray-300 p-2 mb-2 md:p-8 md:m-4">
                  <Bar data={chartData} options={chartOptions} />
                </div>
              </div>
              <div
                className={`absolute inset-0 transition-transform transform ${
                  currentSlide === 1 ? "translate-x-0" : "translate-x-full"
                }`}
                style={{ width: "100%", height: "100%" }}
              >
                <h2 className="text-lg font-semibold mb-4">
                  Completed Items Over the Last 15 Days
                </h2>
                <div className="flex justify-center items-center h-full bg-gray-50 border border-dashed border-gray-300 py-12 m-2">
                  <Line data={lineChartData} options={lineChartOptions} />
                </div>
              </div>
            </div>
            <div className="flex justify-between mt-4">
              <button className=" px-4 py-2" onClick={handlePrevSlide}>
                <IoIosArrowBack />
              </button>
              <button className=" px-4 py-2 " onClick={handleNextSlide}>
                <IoIosArrowForward />
              </button>
            </div>
          </div>

          {/* Side Panel */}
          <div className="bg-white p-6 rounded shadow">
            <h2 className="text-lg font-semibold mb-4">Upcoming Deadlines</h2>
            <ul className="space-y-2">
              {dueItems.map((item) => (
                <li
                  key={item._id}
                  className="p-3 bg-gray-100 rounded border-l-4 border-purple-500"
                >
                  <p className="font-bold">{item.description}</p>
                  <p className="text-sm text-gray-500">
                    Due: {new Date(item.dueDate).toLocaleString()}
                  </p>
                  <p className="text-sm text-gray-500">
                    Checklist: {item.checklistName}
                  </p>
                </li>
              ))}
            </ul>
          </div>
        </div>

        {/* Recent Activity */}
        <div className="mt-6 bg-white p-6 rounded shadow">
          <h2 className="text-lg font-semibold mb-4">Recent Activity</h2>
          <ul className="divide-y divide-gray-200">
            {activities.map((activity, index) => (
              <li
                key={index}
                className="py-3 flex justify-between items-center"
              >
                <p className="flex-shrink-0 w-2/3 break-all">
                  {activity.activity}
                </p>
                <p className="flex-shrink-0 w-1/3 text-sm text-gray-500 text-right">
                  {new Date(activity.timestamp).toLocaleString()}
                </p>
              </li>
            ))}
          </ul>
        </div>

        {/* Notifications Section */}
        <div className="mt-6 bg-white p-6 rounded shadow">
          <h2 className="text-lg font-semibold mb-4">Notifications</h2>
          {notificationsLoading ? (
            <Spinner />
          ) : (
            <>
              <ul className="divide-y divide-gray-200">
                {notifications.map((notification, index) => (
                  <li key={index} className="py-3">
                    <p>{notification.message}</p>
                    <p className="text-sm text-gray-500">
                      {new Date(notification.createdAt).toLocaleString()} -{" "}
                      {notification.status}
                    </p>
                  </li>
                ))}
              </ul>
              <div className="flex justify-between mt-4">
                <button
                  className="px-4 py-2 bg-gray-300 rounded"
                  onClick={handlePrevPage}
                  disabled={currentPage === 1}
                >
                  <FaArrowAltCircleLeft />
                </button>
                <div className="flex items-center justify-center gap-2 flex-wrap">
                  {totalPages > 5 ? (
                    <>
                      {currentPage > 4 && (
                        <>
                          <button
                            className={`px-4 py-2 rounded ${
                              currentPage === 1
                                ? "bg-purple-500 text-white"
                                : "bg-gray-300"
                            }`}
                            onClick={() => setCurrentPage(1)}
                          >
                            1
                          </button>
                          <span>...</span>
                        </>
                      )}
                      {Array.from(
                        { length: Math.min(5, totalPages) },
                        (_, index) => currentPage - 2 + index
                      )
                        .filter((page) => page > 0 && page <= totalPages)
                        .map((page) => (
                          <button
                            key={page}
                            className={`px-4 py-2 rounded ${
                              currentPage === page
                                ? "bg-purple-500 text-white"
                                : "bg-gray-300"
                            }`}
                            onClick={() => setCurrentPage(page)}
                          >
                            {page}
                          </button>
                        ))}
                      {currentPage < totalPages - 3 && (
                        <>
                          <span>...</span>
                          <button
                            className={`px-4 py-2 rounded ${
                              currentPage === totalPages
                                ? "bg-purple-500 text-white"
                                : "bg-gray-300"
                            }`}
                            onClick={() => setCurrentPage(totalPages)}
                          >
                            {totalPages}
                          </button>
                        </>
                      )}
                    </>
                  ) : (
                    Array.from({ length: totalPages }, (_, index) => (
                      <button
                        key={index}
                        className={`px-4 py-2 rounded ${
                          currentPage === index + 1
                            ? "bg-purple-500 text-white"
                            : "bg-gray-300"
                        }`}
                        onClick={() => setCurrentPage(index + 1)}
                      >
                        {index + 1}
                      </button>
                    ))
                  )}
                </div>
                <button
                  className="px-4 py-2 bg-gray-300 rounded"
                  onClick={handleNextPage}
                  disabled={currentPage === totalPages}
                >
                  <FaArrowAltCircleRight />
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
