import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import dashboardService from './dashboardService'
import { extractErrorMessage } from '../../utils'

const initialState = {
  inProgressItems: [],
  deadlineItems: [],
  itemsToStartTomorrow: [],
  overdueItems: [],
  totalInProgress: 0,
  totalDeadline: 0,
  totalToStartTomorrow: 0,
  totalOverdue: 0,
  completedItems: [],
  totalCompleted: 0,
  dueItems: [],
  activities: [],
  focusedWork: {}, // Add focused work to the state
  isLoading: false,
  focusedWorkCount:{},
  completedItemsLastNDays: [],
  highestCompletedInADay: { count: 0, date: null }, // Add highestCompletedInADay to the state
  completedNotInTimeItems: [], // Add completedNotInTimeItems to the state
  totalOverdueCompleted:0
}

// Fetch dashboard data
export const fetchDashboardData = createAsyncThunk(
  'dashboard/fetchDashboardData',
  async (_, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      return await dashboardService.fetchDashboardData(token)
    } catch (error) {
      return thunkAPI.rejectWithValue(extractErrorMessage(error))
    }
  }
)

// Fetch activities data
export const fetchActivitiesData = createAsyncThunk(
  'dashboard/fetchActivitiesData',
  async (_, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      return await dashboardService.fetchActivities(token)
    } catch (error) {
      return thunkAPI.rejectWithValue(extractErrorMessage(error))
    }
  }
)

// Fetch focused work data
export const fetchFocusedWorkData = createAsyncThunk(
  'dashboard/fetchFocusedWorkData',
  async (_, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      return await dashboardService.fetchFocusedWork(token)
    } catch (error) {
      return thunkAPI.rejectWithValue(extractErrorMessage(error))
    }
  }
)

// Create focused work
export const createFocusedWork = createAsyncThunk(
  'dashboard/createFocusedWork',
  async (focusedWorkData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      return await dashboardService.createFocusedWork(focusedWorkData, token)
    } catch (error) {
      return thunkAPI.rejectWithValue(extractErrorMessage(error))
    }
  }
)

// Update focused work items
export const updateFocusedWorkItems = createAsyncThunk(
  'dashboard/updateFocusedWorkItems',
  async (focusedWorkData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      return await dashboardService.updateFocusedWorkItems(focusedWorkData, token)
    } catch (error) {
      return thunkAPI.rejectWithValue(extractErrorMessage(error))
    }
  }
)

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    removeDashboardData: (state) => {
      Object.assign(state, initialState);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchDashboardData.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchDashboardData.fulfilled, (state, action) => {
        state.inProgressItems = action.payload.inProgressItems || []
        state.deadlineItems = action.payload.deadlineItems || []
        state.itemsToStartTomorrow = action.payload.itemsToStartTomorrow || []
        state.overdueItems = action.payload.overdueItems || []
        state.totalInProgress = action.payload.totalInProgress || 0
        state.totalDeadline = action.payload.totalDeadline || 0
        state.totalToStartTomorrow = action.payload.totalToStartTomorrow || 0
        state.totalOverdue = action.payload.totalOverdue || 0
        state.completedItems = action.payload.completedItems || []
        state.totalCompleted = action.payload.totalCompleted || 0
        state.dueItems = action.payload.dueItems || []
        state.activities = action.payload.activities || []
        state.focusedWorkCount = action.payload.focusedWorkCount || {}
        state.completedItemsLastNDays = action.payload.completedItemsLastNDays || []
        state.highestCompletedInADay = action.payload.highestCompletedInADay || { count: 0, date: null }
        state.completedNotInTimeItems = action.payload.completedNotInTimeItems || [] // Handle completedNotInTimeItems
        state.totalOverdueCompleted = action.payload.totalOverdueCompleted || 0 // Handle completedNotInTimeItems
        state.isLoading = false
      })
      .addCase(fetchDashboardData.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(fetchActivitiesData.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchActivitiesData.fulfilled, (state, action) => {
        state.activities = action.payload || []
        state.isLoading = false
      })
      .addCase(fetchActivitiesData.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(fetchFocusedWorkData.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchFocusedWorkData.fulfilled, (state, action) => {
        state.focusedWork = action.payload || []
        state.isLoading = false
      })
      .addCase(fetchFocusedWorkData.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(createFocusedWork.pending, (state) => {
        state.isLoading = true
      })
      .addCase(createFocusedWork.fulfilled, (state, action) => {
        console.log("state.focusedWork",state.focusedWork)
       // state.focusedWork.push(action.payload)
        state.isLoading = false
      })
      .addCase(createFocusedWork.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(updateFocusedWorkItems.pending, (state) => {
        state.isLoading = true
      })
      .addCase(updateFocusedWorkItems.fulfilled, (state, action) => {
        state.focusedWork = action.payload
        state.isLoading = false
      })
      .addCase(updateFocusedWorkItems.rejected, (state) => {
        state.isLoading = false
      })
  },
})
export const { removeDashboardData } = dashboardSlice.actions;
export default dashboardSlice.reducer
