import React, { useState, useEffect, useRef } from "react";
import {
  AiOutlineEdit,
  AiFillCheckCircle,
  AiFillDelete,
  AiFillCloseCircle,
  AiOutlinePlus,
  AiFillTag,
  AiTwotoneTag,
  AiTwotoneTags,
  AiOutlineTag,
  AiFillClockCircle,
  AiOutlineClockCircle,
  AiOutlinePauseCircle,
  AiOutlineStop,
  AiOutlineCheckCircle,
  AiOutlineExclamationCircle,
  AiFillHourglass,
  AiOutlineHourglass,
  AiOutlineSwap,
  AiTwotoneHourglass,
  AiOutlineTags,
  AiOutlineDelete,
  AiOutlineComment,
} from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import {
  createOrUpdateACheckListItem,
  deleteACheckListItem,
  filterItemsByTags,
  moveCheckListItem,
  getItemsByCheckListID,
  moveCheckListItems,
  addCommentToCheckListItem,
} from "../features/checklistItem/checkListItemSlice";
import { toast } from "react-toastify";
import PopupComponent from "../components/PopupComponent";
import Tag from "../components/Tag";
import TagPopup from "../components/TagPopup";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Counter from "./Counter";
import UnitType from "./UnitType";

const statusOptions = [
  {
    value: "pending",
    label: "Pending",
    icon: <AiOutlineClockCircle className="text-yellow-500" />,
  },
  {
    value: "in progress",
    label: "In Progress",
    icon: <AiOutlineHourglass className="text-blue-500" />,
  },
  {
    value: "completed",
    label: "Completed",
    icon: <AiOutlineCheckCircle className="text-green-500" />,
  },
  {
    value: "on hold",
    label: "On Hold",
    icon: <AiOutlineStop className="text-orange-500" />,
  },
  {
    value: "blocked",
    label: "Blocked",
    icon: <AiOutlineExclamationCircle className="text-red-500" />,
  },
];

const priorityOptions = [
  { value: "", label: "Select Priority" },
  { value: "low", label: "Low" },
  { value: "medium", label: "Medium" },
  { value: "high", label: "High" },
];

function CheckListItem({ item, onSelect = () => {}, isSelected }) {
  const [Item, setItem] = useState(item);
  const [isEditing, setIsEditing] = useState(Item.isEditing || false);
  const [checked, setChecked] = useState(Item.status === "completed");
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isTagPopupOpen, setIsTagPopupOpen] = useState(false);
  const [selectedTags, setSelectedTags] = useState([]);
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [isRangeMode, setIsRangeMode] = useState(false);
  const [targetDateRange, setTargetDateRange] = useState({
    startDate: item.startDate || null,
    endDate: item.endDate || null,
  });
  const [targetDate, setTargetDate] = useState(item.startDate || null);
  const [status, setStatus] = useState(Item.status);
  const [priority, setPriority] = useState(item.priority);
  const [isStatusDropdownOpen, setIsStatusDropdownOpen] = useState(false);
  const [isMovePopupOpen, setIsMovePopupOpen] = useState(false);
  const [selectedCheckListID, setSelectedCheckListID] = useState("");
  const [count, setCount] = useState(0);
  const [unitType, setUnitType] = useState("");
  const [comments, setComments] = useState(Item.comments || []);
  const [newComment, setNewComment] = useState("");
  const [isCommentsPopupOpen, setIsCommentsPopupOpen] = useState(false);
  const statusDropdownRef = useRef(null);
  const calendarRef = useRef(null);
  const { checkLists } = useSelector((state) => state.checkLists);

  const dispatch = useDispatch();

  useEffect(() => {
    setItem(item);
    setTargetDateRange({
      startDate: item.startDate ? new Date(item.startDate) : null,
      endDate: item.endDate ? new Date(item.endDate) : null,
    });
    setTargetDate(item.startDate ? new Date(item.startDate) : null);
    setStatus(item.status);
    setCount(item.counter || 0);
    setUnitType(item.unitType);
  }, [item]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        statusDropdownRef.current &&
        !statusDropdownRef.current.contains(event.target)
      ) {
        setIsStatusDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (calendarRef.current && !calendarRef.current.contains(event.target)) {
        setIsCalendarOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleCloseClick = () => {
    if (!Item._id) {
      setItem({ ...Item, description: "" });
    }
    setIsEditing(false);
  };

  const handleStatusChange = (newStatus) => {
    setStatus(newStatus);
    setIsEditing(true);
    setIsStatusDropdownOpen(false);
  };

  const handlePriorityChange = (newPriority) => {
    setPriority(newPriority);
    // setIsEditing(true);
    dispatch(
      createOrUpdateACheckListItem({
        checkListID: Item.checkListID,
        description: Item.description,
        status: status,
        itemID: Item._id,
        tags: Item.tags,
        startDate: targetDateRange.startDate,
        endDate: targetDateRange.endDate,
        counter: count,
        unitType: unitType,
        category: Item.category,
        comments: comments,
        priority: newPriority,
      })
    )
      .unwrap()
      .then(() => {
        toast.success("Priority updated");
      })
      .catch((error) => {
        toast.error(error.message || "Something went wrong!", {
          autoClose: 2000,
        });
      });
  };

  const handleSaveClick = () => {
    if (Item.description && Item.description.length > 0) {
      dispatch(
        createOrUpdateACheckListItem({
          checkListID: Item.checkListID,
          description: Item.description,
          status: status,
          itemID: Item._id,
          tags: Item.tags,
          startDate: targetDateRange.startDate,
          endDate: targetDateRange.endDate,
          counter: count,
          unitType: unitType,
          category: Item.category,
          comments: comments,
          priority: priority,
        })
      )
        .unwrap()
        .then(() => {
          toast.success("Saved");
          setChecked(status === "completed"); // Update checked state
        })
        .catch((error) => {
          toast.error(error.message || "Something went wrong!", {
            autoClose: 2000,
          });
        });
      setIsEditing(false);
    }
  };

  const handleDeleteClick = () => {
    setIsPopupOpen(true);
  };

  const confirmDelete = () => {
    if (Item._id) {
      dispatch(
        deleteACheckListItem({
          checkListID: Item.checkListID,
          itemID: Item._id,
        })
      )
        .unwrap()
        .then(() => {
          toast.success("Deleted");
        })
        .catch(toast.error);
      setIsEditing(false);
    }
    setIsPopupOpen(false);
  };

  const cancelDelete = () => {
    setIsPopupOpen(false);
  };

  const handleChange = (event) => {
    setItem({ ...Item, description: event.target.value });
  };

  const handleTagPopupOpen = () => {
    setIsTagPopupOpen(true);
  };

  const handleTagPopupClose = () => {
    setIsTagPopupOpen(false);
  };

  const handleTagDelete = (tag) => {
    const updatedTags = Item.tags.filter((t) => t !== tag);
    setItem({ ...Item, tags: updatedTags });
    dispatch(
      createOrUpdateACheckListItem({
        checkListID: Item.checkListID,
        description: Item.description,
        status: Item.status,
        itemID: Item._id,
        tags: updatedTags,
        startDate: targetDateRange.startDate,
        endDate: targetDateRange.endDate,
        counter: count,
        unitType: unitType,
        category: Item.category,
      })
    )
      .unwrap()
      .then(() => {
        toast.success("Tag deleted");
      })
      .catch((error) => {
        toast.error(error.message || "Something went wrong!", {
          autoClose: 2000,
        });
      });
  };

  const handleTagClick = (tag) => {
    setSelectedTags((prevSelectedTags) =>
      prevSelectedTags.includes(tag)
        ? prevSelectedTags.filter((t) => t !== tag)
        : [...prevSelectedTags, tag]
    );
  };

  useEffect(() => {
    if (selectedTags.length > 0) {
      dispatch(filterItemsByTags(selectedTags));
    }
  }, [selectedTags, dispatch]);

  const urlify = (text) => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    return text.split(urlRegex).map((part, index) => {
      if (part.match(urlRegex)) {
        return (
          <a href={part} target="_blank" key={index} className="break-all">
            {part}
          </a>
        );
      }
      return part;
    });
  };

  const handleCalendarClick = () => {
    setIsCalendarOpen(!isCalendarOpen);
  };

  const handleDateChange = (dates) => {
    if (isRangeMode) {
      const [start, end] = dates;
      setTargetDateRange({
        startDate: start, //? new Date(start) : null,
        endDate: end, // ? new Date(end) : null,
      });
    } else {
      setTargetDate(dates);
    }
  };

  const handleDateConfirm = () => {
    dispatch(
      createOrUpdateACheckListItem({
        checkListID: Item.checkListID,
        description: Item.description,
        status: Item.status,
        itemID: Item._id,
        tags: Item.tags,
        counter: count,
        unitType: unitType,
        category: Item.category,
        startDate: isRangeMode
          ? targetDateRange.startDate
            ? new Date(targetDateRange.startDate)
            : null
          : targetDate
          ? new Date(targetDate)
          : null,
        endDate: isRangeMode
          ? targetDateRange.endDate
            ? new Date(targetDateRange.endDate)
            : null
          : null //targetDate
          ? null //new Date(targetDate)
          : null,
      })
    )
      .unwrap()
      .then(() => {
        toast.success("Date updated");
      })
      .catch((error) => {
        toast.error(error.message || "Something went wrong!", {
          autoClose: 2000,
        });
      });
    setIsCalendarOpen(false);
  };

  const handleMoveConfirm = () => {
    dispatch(
      moveCheckListItems({
        itemID: Item._id,
        newCheckListID: selectedCheckListID,
      })
    )
      .unwrap()
      .then((movedItem) => {
        toast.success("Item moved");
        setItem(null); // Update the state to reflect the moved item
        dispatch(getItemsByCheckListID(selectedCheckListID)); // Fetch items for the new checklist
      })
      .catch((error) => {
        toast.error(error.message || "Something went wrong!", {
          autoClose: 2000,
        });
      });
    setIsMovePopupOpen(false);
  };

  const handleMoveCancel = () => {
    setIsMovePopupOpen(false);
  };

  const formatDate = (date) => {
    if (!date) return "";
    const options = {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit", // Add seconds to the timestamp
    };
    return new Date(date).toLocaleDateString(undefined, options);
  };

  const calculateTotalHours = (startDate, endDate) => {
    if (!startDate || !endDate) return 0;
    const diffInMs = new Date(endDate) - new Date(startDate);
    return Math.round(diffInMs / (1000 * 60 * 60));
  };

  const calculateHoursFromNow = (date) => {
    if (!date) return 0;
    const now = new Date();
    const diffInMs = new Date(date) - now;
    if (diffInMs < 0) return 0; // Return 0 if the date has gone past the current date
    return Math.round(diffInMs / (1000 * 60 * 60));
  };

  const selectedStatusIcon = statusOptions.find(
    (option) => option.value === status
  )?.icon;

  const handleLongPress = (e) => {
    e.preventDefault();
    console.log("Right-click detected");
    onSelect(Item._id);
  };

  const handleCountChange = (e) => {
    setCount(e.target.value);
  };

  const handleUnitTypeChange = (e) => {
    setUnitType(e.target.value);
  };

  const handleAddComment = () => {
    if (newComment.trim()) {
      const updatedComments = [
        ...comments,
        { comment: newComment, timestamp: new Date().toISOString() },
      ];
      dispatch(
        createOrUpdateACheckListItem({
          checkListID: Item.checkListID,
          description: Item.description,
          status: Item.status,
          itemID: Item._id,
          tags: Item.tags,
          startDate: targetDateRange.startDate,
          endDate: targetDateRange.endDate,
          counter: count,
          unitType: unitType,
          category: Item.category,
          comments: updatedComments,
        })
      )
        .unwrap()
        .then((updatedItem) => {
          setComments(updatedItem.comments);
          setNewComment("");
          toast.success("Comment added");
        })
        .catch((error) => {
          toast.error(error.message || "Something went wrong!", {
            autoClose: 2000,
          });
        });
    }
  };

  const handleCommentsClick = () => {
    setIsCommentsPopupOpen(true);
  };

  const handleCommentsPopupClose = () => {
    setIsCommentsPopupOpen(false);
  };

  return !Item ? (
    <div>
      <h1>No items yet...</h1>
    </div>
  ) : (
    <div
      className={`flex flex-col text-gray-400 font-thin mt-4 ${
        isSelected ? "bg-gray-200" : ""
      }`}
      onContextMenu={handleLongPress} // Add long press handler
    >
      {/*tags */}
      <div className="flex flex-row flex-wrap gap-2 mt-2 ml-20">
        {Item.tags &&
          Item.tags.map((tag, index) => (
            <Tag
              key={index}
              tag={tag}
              onDelete={handleTagDelete}
              onClick={handleTagClick}
            />
          ))}
      </div>
      {/*complete row : status + text + buttons */}
      <div className="flex flex-row ">
        <div className="mr-0 relative" ref={statusDropdownRef}>
          <button
            onClick={() => setIsStatusDropdownOpen(!isStatusDropdownOpen)}
            className="rounded-select flex-grow flex-shrink basis-0 p-2 md:ml-2  w-10 h-10 accent-gray-600 opacity-80"
          >
            {selectedStatusIcon}
          </button>
          {isStatusDropdownOpen && (
            <div className="absolute top-8 left-4 bg-white border rounded  z-10">
              {statusOptions.map((option) => (
                <button
                  key={option.value}
                  onClick={() => handleStatusChange(option.value)}
                  className="flex items-center p-2 w-full text-left hover:bg-gray-100"
                >
                  {option.icon} <span className="ml-2">{option.label}</span>
                </button>
              ))}
            </div>
          )}
        </div>
        <div className={`box ${isEditing ? "editing-mode" : "view-mode"}`}>
          {isEditing ? (
            <div>
              <textarea
                rows="10"
                cols="400"
                value={Item.description}
                onChange={handleChange}
                className="w-full h-24 text-gray-600 border"
              />
              <button
                onClick={handleSaveClick}
                className="px-4 py-1 m-2 w-10 h-10 text-emerald-400"
              >
                <AiFillCheckCircle />
              </button>
              <button
                onClick={handleCloseClick}
                className="px-4 py-1 m-2 w-10 h-10 text-red opacity-60"
              >
                <AiFillCloseCircle />
              </button>

              {Item &&
                Item.category &&
                ["shopping", "course", "recurring"].includes(
                  Item.category.name
                ) && (
                  <div>
                    <Counter
                      label={
                        Item.category.name === "recurring" ? "Every" : "Count"
                      }
                      count={count}
                      onCountChange={handleCountChange}
                    />
                    <UnitType
                      unitType={unitType}
                      onUnitTypeChange={handleUnitTypeChange}
                    />
                  </div>
                )}
              {/* <div className="mt-4">
                <h3 className="text-lg font-semibold">Comments</h3>
                <ul className="list-disc pl-5">
                  {comments.map((comment, index) => (
                    <li key={index} className="mb-2">
                      {comment.comment}{" "}
                      <span className="text-xs text-gray-500">
                        {formatDate(comment.timestamp)}
                      </span>
                    </li>
                  ))}
                </ul>
                <div className="flex mt-2">
                  <input
                    type="text"
                    value={newComment}
                    onChange={(e) => setNewComment(e.target.value)}
                    className="border p-2 flex-grow"
                    placeholder="Add a comment"
                  />
                  <button
                    onClick={handleAddComment}
                    className="bg-blue-500 text-white px-4 py-2 ml-2 rounded"
                  >
                    Add
                  </button>
                </div>
              </div> */}
            </div>
          ) : (
            <div>
              <div
                className={`${
                  checked ? "line-through text-gray-400" : "text-gray-600"
                }  my-2 mx-1 flex-grow flex-shrink-0 basis-0 break-all`}
              >
                {urlify(Item.description)}
                <span className="ml-2 text-sm">
                  {count ? count + "" + unitType : ""}
                </span>
                <span className=" text-xs text-gray-400 font-thin ">
                  updated at {formatDate(Item.updatedAt)}
                </span>
              </div>

              <div className="flex-grow flex-shrink basis-0 flex items-center flex-wrap">
                <button
                  onClick={handleEditClick}
                  className="px-4 py-1 m-2 w-10 h-10"
                >
                  <AiOutlineEdit />
                </button>
                <div class="border-l border-gray-300 h-5"></div>
                <button
                  onClick={handleDeleteClick}
                  className="px-4 py-1 m-2 w-10 h-10"
                >
                  <AiOutlineDelete />
                </button>
                <div class="border-l border-gray-300 h-5"></div>
                <button
                  onClick={handleCommentsClick}
                  className="px-4 py-1 m-2 w-10 h-10 relative"
                >
                  <AiOutlineComment />
                  {comments.length > 0 && (
                    <span className="absolute top-0 right-0 bg-red text-gray-300 rounded-full text-xs w-4 h-4 flex items-center justify-center">
                      {comments.length}
                    </span>
                  )}
                </button>
                <div class="border-l border-gray-300 h-5"></div>
                <button
                  onClick={handleTagPopupOpen}
                  className="px-4 py-1 m-2 w-10 h-10"
                >
                  <AiOutlineTags />
                </button>
                <div class="border-l border-gray-300 h-5"></div>

                <div>
                  {/* <h3 className="text-xs font-semibold ">Priority</h3> */}
                  <select
                    value={priority}
                    onChange={(e) => handlePriorityChange(e.target.value)}
                    className="ml-2 bg-white py-2 text-xs font-semibold text-center"
                  >
                    {priorityOptions.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                </div>
                <div class="border-l border-gray-300 h-5 ml-2"></div>
                <div className="flex items-center">
                  <button
                    onClick={handleCalendarClick}
                    className="px-4 py-1 m-2 w-10 h-10"
                  >
                    {Item.startDate || Item.endDate ? (
                      <AiOutlineHourglass />
                    ) : (
                      <AiOutlineClockCircle />
                    )}
                  </button>
                  {isRangeMode ||
                  (targetDateRange.startDate && targetDateRange.endDate)
                    ? targetDateRange.startDate &&
                      targetDateRange.endDate && (
                        <div>
                          <span className="text-gray-400 font-thin md:text-sm text-xs">
                            {`${formatDate(
                              targetDateRange.startDate
                            )} - ${formatDate(
                              targetDateRange.endDate
                            )} (${calculateTotalHours(
                              targetDateRange.startDate,
                              targetDateRange.endDate
                            )} hours)`}
                          </span>
                        </div>
                      )
                    : targetDate && (
                        <div className="w-full sm:w-auto">
                          <span className="text-gray-400 font-thin md:text-sm text-xs">
                            {`${formatDate(
                              targetDate
                            )} (${calculateHoursFromNow(targetDate)} hours)`}
                          </span>
                        </div>
                      )}
                </div>
                {isCalendarOpen && (
                  <div
                    ref={calendarRef}
                    className="absolute flex flex-col md:left-80 -left-4 z-50 bg-white p-4 shadow-lg w-96"
                  >
                    <div className="flex items-center mb-4">
                      <label className="mr-2">Range Mode</label>
                      <input
                        type="checkbox"
                        checked={isRangeMode}
                        onChange={() => setIsRangeMode(!isRangeMode)}
                      />
                    </div>
                    <DatePicker
                      selected={
                        isRangeMode
                          ? (targetDateRange.startDate &&
                              new Date(targetDateRange.startDate)) ||
                            new Date()
                          : targetDate && new Date(targetDate) //|| new Date()
                      }
                      onChange={(dates) => handleDateChange(dates)}
                      startDate={isRangeMode ? targetDateRange.startDate : null}
                      endDate={isRangeMode ? targetDateRange.endDate : null}
                      selectsRange={isRangeMode}
                      showTimeSelect
                      timeFormat="HH:mm"
                      timeIntervals={15}
                      timeCaption="time"
                      dateFormat="MMMM d, yyyy h:mm"
                      minDate={new Date()}
                      className="w-full"
                      isClearable={true}
                      todayButton="Today"
                      placeholderText="Select date here..."
                    />
                    <button
                      onClick={handleDateConfirm}
                      className="mt-4 bg-blue-500 text-white px-4 py-2 rounded"
                    >
                      Confirm
                    </button>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
      <PopupComponent isOpen={isPopupOpen} onClose={cancelDelete}>
        <h2 className="flex items-center justify-center text-xl font-bold">
          Confirm Deletion
        </h2>
        <p>Are you sure you want to delete this item?</p>
        <div className="flex items-center justify-center">
          <button
            onClick={confirmDelete}
            className="bg-red text-white px-4 py-2 rounded m-2"
          >
            Yes, Delete
          </button>
          <button
            onClick={cancelDelete}
            className="bg-gray-500 text-white px-4 py-2 rounded m-2"
          >
            Cancel
          </button>
        </div>
      </PopupComponent>
      <PopupComponent isOpen={isMovePopupOpen} onClose={handleMoveCancel}>
        <h2 className="flex items-center justify-center text-xl font-bold">
          Move Checklist Item
        </h2>
        <p>Select the new checklist:</p>
        <select
          value={selectedCheckListID}
          onChange={(e) => setSelectedCheckListID(e.target.value)}
          className="border p-2 w-full"
        >
          <option value="">Select Checklist</option>
          {checkLists.map((checklist) => (
            <option key={checklist._id} value={checklist._id}>
              {checklist.name}
            </option>
          ))}
        </select>
        <div className="flex items-center justify-center">
          <button
            onClick={handleMoveConfirm}
            className="bg-blue-500 text-white px-4 py-2 rounded m-2"
          >
            Move
          </button>
          <button
            onClick={handleMoveCancel}
            className="bg-gray-500 text-white px-4 py-2 rounded m-2"
          >
            Cancel
          </button>
        </div>
      </PopupComponent>
      <PopupComponent
        isOpen={isCommentsPopupOpen}
        onClose={handleCommentsPopupClose}
      >
        <h2 className="flex items-center justify-center text-xl font-bold">
          Comments
        </h2>
        <ul className="list-disc pl-5">
          {comments.map((comment, index) => (
            <li key={index} className="text-gray-500 mb-2">
              {comment.comment}{" "}
              <span className="text-xs font-thin text-gray-400">
                {formatDate(comment.timestamp)}
              </span>
            </li>
          ))}
        </ul>
        <div className="flex mt-2">
          <input
            type="text"
            value={newComment}
            onChange={(e) => setNewComment(e.target.value)}
            className="border p-2 flex-grow"
            placeholder="Add a comment"
          />
          <button
            onClick={handleAddComment}
            className="bg-purple-500 text-white px-4 py-2 ml-2 rounded"
          >
            Add
          </button>
        </div>
      </PopupComponent>
      <TagPopup
        item={Item}
        isOpen={isTagPopupOpen}
        onClose={handleTagPopupClose}
        onTagAdded={(updatedItem) => setItem(updatedItem)}
      />
    </div>
  );
}

export default CheckListItem;
