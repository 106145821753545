import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import notificationService from './notificationService'
import { extractErrorMessage } from '../../utils'

const initialState = {
  notifications: [],
  isLoading: false,
  currentPage: 1,
  totalPages: 1,
}

// Fetch user notifications
export const fetchUserNotifications = createAsyncThunk(
  'notifications/fetchUserNotifications',
  async (page = 1, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      return await notificationService.fetchUserNotifications(token, page)
    } catch (error) {
      return thunkAPI.rejectWithValue(extractErrorMessage(error))
    }
  }
)

export const notificationSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    removeNotificationData: (state) => {
      Object.assign(state, initialState)
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserNotifications.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchUserNotifications.fulfilled, (state, action) => {
        state.notifications = action.payload.notifications || []
        state.currentPage = action.payload.currentPage
        state.totalPages = action.payload.totalPages
        state.isLoading = false
      })
      .addCase(fetchUserNotifications.rejected, (state) => {
        state.isLoading = false
      })
  },
})
export const { removeNotificationData } = notificationSlice.actions;
export default notificationSlice.reducer
