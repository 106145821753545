import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import goalMapService from './goalMapService';
import { extractErrorMessage } from '../../utils';

const initialState = {
  goalMaps: [],
  selectedGoalMap: null,
  isLoading: false,
};

// Fetch all goal maps
export const fetchGoalMaps = createAsyncThunk(
  'goalMap/fetchGoalMaps',
  async (_, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await goalMapService.fetchGoalMaps(token);
    } catch (error) {
      return thunkAPI.rejectWithValue(extractErrorMessage(error));
    }
  }
);

// Fetch a single goal map by ID
export const fetchGoalMapById = createAsyncThunk(
  'goalMap/fetchGoalMapById',
  async (goalMapId, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await goalMapService.fetchGoalMapById(goalMapId, token);
    } catch (error) {
      return thunkAPI.rejectWithValue(extractErrorMessage(error));
    }
  }
);

// Create a goal map
export const createGoalMap = createAsyncThunk(
  'goalMap/createGoalMap',
  async (goalMapData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await goalMapService.createGoalMap(goalMapData, token);
    } catch (error) {
      return thunkAPI.rejectWithValue(extractErrorMessage(error));
    }
  }
);

// Update a goal map
export const updateGoalMap = createAsyncThunk(
  'goalMap/updateGoalMap',
  async ({ goalMapId, updatedData }, thunkAPI) => {
    try {
        console.log(goalMapId, updatedData)
      const token = thunkAPI.getState().auth.user.token;
      return await goalMapService.updateGoalMap(goalMapId, updatedData, token);
    } catch (error) {
      return thunkAPI.rejectWithValue(extractErrorMessage(error));
    }
  }
);

// Delete a goal map
export const deleteGoalMap = createAsyncThunk(
  'goalMap/deleteGoalMap',
  async (goalMapId, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await goalMapService.deleteGoalMap(goalMapId, token);
    } catch (error) {
      return thunkAPI.rejectWithValue(extractErrorMessage(error));
    }
  }
);

export const goalMapSlice = createSlice({
  name: 'goalMap',
  initialState,
  reducers: {
    removeGoalMapData: (state) => {
      Object.assign(state, initialState);
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchGoalMaps.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchGoalMaps.fulfilled, (state, action) => {
        state.goalMaps = action.payload;
        state.isLoading = false;
      })
      .addCase(fetchGoalMaps.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(fetchGoalMapById.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchGoalMapById.fulfilled, (state, action) => {
        state.selectedGoalMap = action.payload;
        state.isLoading = false;
      })
      .addCase(fetchGoalMapById.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(createGoalMap.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createGoalMap.fulfilled, (state, action) => {
        state.goalMaps=state.goalMaps.filter((item)=>item._id)
                
                const itemIndex = state.goalMaps.findIndex((item)=>{
                    
                  return item._id === action.payload._id
                  });
                  
                  if (itemIndex !== -1) {
                   // Replace the object at the found index with the updated item
                   state.goalMaps[itemIndex] = action.payload;
                 //  console.log(`Updated item with _id ${itemIdToUpdate}`);
                 } else {
                   state.goalMaps.push(action.payload);
                 //  console.log(`Item with _id ${itemIdToUpdate} not found.`);
                 }
        state.isLoading = false;
      })
      .addCase(createGoalMap.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(updateGoalMap.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateGoalMap.fulfilled, (state, action) => {
         state.goalMaps=state.goalMaps.filter((item)=>item._id)
                
                const itemIndex = state.goalMaps.findIndex((item)=>{
                    
                  return item._id === action.payload._id
                  });
                  
                  if (itemIndex !== -1) {
                   // Replace the object at the found index with the updated item
                   state.goalMaps[itemIndex] = action.payload;
                 //  console.log(`Updated item with _id ${itemIdToUpdate}`);
                 } else {
                   state.goalMaps.push(action.payload);
                 //  console.log(`Item with _id ${itemIdToUpdate} not found.`);
                 }
        state.isLoading = false;
      })
      .addCase(updateGoalMap.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(deleteGoalMap.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteGoalMap.fulfilled, (state, action) => {
        state.goalMaps = state.goalMaps.filter(
          (goalMap) => goalMap._id !== action.payload._id
        );
        state.isLoading = false;
      })
      .addCase(deleteGoalMap.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export const { removeGoalMapData } = goalMapSlice.actions;
export default goalMapSlice.reducer;
