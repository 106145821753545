import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { FaSignInAlt } from "react-icons/fa";
import { useSelector, useDispatch } from "react-redux";
import { googleLogin, login, register } from "../features/auth/authSlice";
import Spinner from "../components/Spinner";
import GoogleSignInButton from "../components/GoogleSignInButton";
import { setUser } from "../features/auth/authSlice";
import { useLocation } from "react-router-dom";

function Login() {
  const [currentIndex, setCurrentIndex] = useState(0);
  const slides = [
    {
      title: "Stay Organized",
      description:
        "Our checklist tool helps you keep track of all your tasks efficiently.",
      svg: (
        <svg
          className="w-24 h-24 mx-auto mt-4 text-gray-400"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M4 6h16M4 12h8m-8 6h16"
          />
        </svg>
      ),
    },
    {
      title: "Improve Productivity",
      description:
        "Boost your efficiency by planning and tracking tasks with ease.",
      svg: (
        <svg
          className="w-24 h-24 mx-auto mt-4 text-gray-400"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M5 13l4 4L19 7"
          />
        </svg>
      ),
    },
    {
      title: "Collaborate Easily",
      description: "Share and manage tasks with your team seamlessly.",
      svg: (
        <svg
          className="w-24 h-24 mx-auto mt-4 text-gray-400"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M13 16h-1v-4h-1m2 4v-4m-4 4v-4m1 4h.01M4 6h16M4 12h8m-8 6h16"
          />
        </svg>
      ),
    },
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % slides.length);
    }, 3000);
    return () => clearInterval(interval);
  }, [slides.length]);

  const [searchParams, setSearchParams] = useState(() => {
    return new URLSearchParams(window.location.search);
  });

  useEffect(() => {
    const username = searchParams.get("username");
    const email = searchParams.get("email");
    const token = searchParams.get("token");
    const id = searchParams.get("_id");
    if (username && email && token && id) {
      const id = searchParams.get("_id");
      dispatch(
        setUser({ name: username, email: email, token: token, _id: id })
      );
      navigate("/");
    }
  }, [searchParams]);

  const [formData, setFormData] = useState({
    email: "",
    password: "",
    name: "",
  });

  const { email, password, name } = formData;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { isLoading } = useSelector((state) => state.auth);

  const onChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  // NOTE: no need for useEffect here as we can catch the
  // AsyncThunkAction rejection in our onSubmit or redirect them on the
  // resolution
  // Side effects shoulld go in event handlers where possible
  // source: - https://beta.reactjs.org/learn/keeping-components-pure#where-you-can-cause-side-effects

  const googleLoginHandler = (e) => {
    dispatch(googleLogin())
      .unwrap()
      .then((user) => {
        // Ensure user and user.name exist
        if (user && user.name) {
          toast.success(`Logged in as ${user.name}`);
          navigate("/");
        } else {
          //toast.error("Login failed");
        }
      })
      .catch((error) => {
        toast.error(error.message || "Google login failed");
      });
  };

  const onSubmit = (e) => {
    e.preventDefault();

    const userData = {
      email,
      password,
      name,
    };

    if (email && password) {
      dispatch(register(userData))
        .unwrap()
        .then((user) => {
          // NOTE: by unwrapping the AsyncThunkAction we can navigate the user after
          // getting a good response from our API or catch the AsyncThunkAction
          // rejection to show an error message
          if (
            user.token &&
            typeof user.token !== "object" &&
            user.token !== ""
          ) {
            toast.success(`Logged in as ${user.name}`);
            navigate("/");
          } else {
            toast.error("Login failed");
          }
        })
        .catch((error) => {
          toast.error(error.message || "Login failed");
        });
    } else {
      toast.error("Please enter your email and password");
    }
  };

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <div className="flex flex-col md:flex-row h-screen ">
      {/* Left Side: Login Form */}
      <div className="md:w-1/2 flex items-center justify-center bg-white relative">
        <div className="min-h-screen flex items-center justify-center bg-gray-100 ">
          {/* Background SVG Pattern */}
          <div
            className="absolute inset-0 bg-gray-50"
            style={{
              backgroundImage: `
            url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 24 24" fill="none" stroke="%23E5E7EB" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"%3E%3Crect x="3" y="3" width="18" height="18" rx="2" ry="2"/%3E%3Cline x1="3" y1="9" x2="21" y2="9"/%3E%3Cline x1="9" y1="21" x2="9" y2="9"/%3E%3C/svg%3E')
          `,
              backgroundSize: "40px 40px",
            }}
          ></div>

          {/* Login Form */}
          <div className="relative z-10 bg-white p-8 rounded-lg shadow-lg w-full max-w-md">
            <h1 className="text-3xl font-bold text-gray-700 text-center mb-6">
              Sign Up
            </h1>

            {/* Username Input */}
            <form>
              {/* name Input */}
              <div className="mb-4">
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700"
                >
                  Name
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={name}
                  onChange={onChange}
                  placeholder="Enter your name"
                  className="mt-1 block w-full px-4 py-2 text-gray-700 border border-gray-300 rounded-md shadow-sm focus:ring-gray-500 focus:border-gray-500"
                />
              </div>

              <div className="mb-4">
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700"
                >
                  Email
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={email}
                  onChange={onChange}
                  placeholder="Enter your email"
                  className="mt-1 block w-full px-4 py-2 text-gray-700 border border-gray-300 rounded-md shadow-sm focus:ring-gray-500 focus:border-gray-500"
                />
              </div>

              {/* Password Input */}
              <div className="mb-4">
                <label
                  htmlFor="password"
                  className="block text-sm font-medium text-gray-700"
                >
                  Password
                </label>
                <input
                  type="password"
                  id="password"
                  name="password"
                  value={password}
                  onChange={onChange}
                  placeholder="Enter your password"
                  className="mt-1 block w-full px-4 py-2  text-gray-700 border border-gray-300 rounded-md shadow-sm focus:ring-gray-500 focus:border-gray-500"
                />
              </div>

              {/* Login Button */}
              <div className="mb-4">
                <button
                  type="submit"
                  onClick={onSubmit}
                  className="w-full bg-gray-500 text-white py-2 rounded-lg shadow-md hover:bg-gray-600 focus:ring-4 focus:ring-gray-300 transition duration-300"
                >
                  Sign Up
                </button>
              </div>

              {/* Divider */}
              <div className="flex items-center justify-between my-4">
                <span className="w-full h-px bg-gray-300"></span>
                <span className="text-sm text-gray-500 px-2">or</span>
                <span className="w-full h-px bg-gray-300"></span>
              </div>

              {/* Google Login */}
              <button
                type="button"
                className="w-full flex items-center border hover:bg-gray-400 justify-center space-x-2 bg-red-500 text-gray-700 py-2 px-4 rounded-lg hover:bg-red-600 focus:ring-4 focus:ring-red-300 transition duration-200 shadow-sm"
                onClick={googleLoginHandler}
              >
                <svg
                  className="w-5 h-5"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 48 48"
                  fill="none"
                >
                  <path
                    fill="#EA4335"
                    d="M24 9.5c3.1 0 5.9 1.1 8.1 3.1l6-6C34.8 3 29.7 1 24 1 14.8 1 6.8 6.6 2.9 14.5l7.3 5.6C12.4 12.6 17.7 9.5 24 9.5z"
                  />
                  <path
                    fill="#4285F4"
                    d="M46.1 24.5c0-1.7-.2-3.4-.5-5H24v9.5h12.7c-.6 3-2.2 5.5-4.7 7.2l7.2 5.6c4.2-3.9 7-9.7 7-16.3z"
                  />
                  <path
                    fill="#FBBC05"
                    d="M10.2 28.6c-.5-1.5-.8-3-.8-4.6s.3-3.1.8-4.6l-7.3-5.6C1.4 17.7 0 20.7 0 24s1.4 6.3 3.8 9.2l7.3-5.6z"
                  />
                  <path
                    fill="#34A853"
                    d="M24 48c6.5 0 12-2.1 16-5.7l-7.2-5.6c-2.2 1.5-5 2.4-8.8 2.4-6.3 0-11.6-4.1-13.5-9.7l-7.3 5.6C6.8 41.4 14.8 48 24 48z"
                  />
                  <path fill="none" d="M0 0h48v48H0z" />
                </svg>
                <span>Sign up using Google</span>
              </button>
            </form>
          </div>
        </div>
      </div>

      {/* Right Side: Slider */}
      <div className="md:w-1/2 bg-gray-50 flex items-center justify-center p-6 md:p-16">
        <div className="w-full max-w-lg text-center">
          <h2 className="text-xl font-bold text-gray-800">
            {slides[currentIndex].title}
          </h2>
          <p className="text-gray-600 mt-2">
            {slides[currentIndex].description}
          </p>
          {slides[currentIndex].svg}
        </div>
      </div>
    </div>
  );
}

export default Login;
