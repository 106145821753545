import React, { useState } from "react";
import {
  FaCog,
  FaSignInAlt,
  FaSignOutAlt,
  FaUser,
  FaClipboard,
} from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { login, logout } from "../features/auth/authSlice";
import { removeCheckListData } from "../features/checklist/checkListSlice";
import { removeCheckListItemData } from "../features/checklistItem/checkListItemSlice";
import { removeAdminData } from "../features/admin/adminSlice";
import { removeCategoryData } from "../features/category/categorySlice";
import { removeDashboardData } from "../features/dashboard/dashboardSlice";
import { removeGoalMapData } from "../features/goalmap/goalMapSlice";
import { removeNotificationData } from "../features/notifications/notificationSlice";

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const onLogout = () => {
    dispatch(logout());
    dispatch(removeCheckListData());
    dispatch(removeCheckListItemData());
    dispatch(removeAdminData());
    dispatch(removeCategoryData());
    dispatch(removeDashboardData());
    dispatch(removeGoalMapData());
    dispatch(removeNotificationData());
    navigate("/login");
  };

  return (
    <nav className="bg-gray-50 opacity-95 shadow-md">
      <div className="container mx-auto px-4">
        <div className="flex justify-between items-center h-16">
          {/* Hamburger Menu for Small Screens */}
          <div className="sm:hidden flex items-center">
            <button
              type="button"
              className="text-gray-500 hover:text-gray-700 focus:outline-none"
              onClick={toggleMenu}
            >
              <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16m-7 6h7"
                ></path>
              </svg>
            </button>
          </div>

          {/* Logo */}
          <div className="text-2xl font-bold text-gray-700 flex-1 text-center sm:text-left">
            <Link to="/">List-It-All</Link>
          </div>

          {/* Navigation Links */}
          <div className="hidden sm:flex space-x-6 mx-2 items-center justify-center">
            {user ? (
              <>
                <button className="text-gray-700 flex items-center hover:opacity-20 gap-1">
                  <FaUser /> {user.name}
                </button>
                {user.userRoles &&
                  (user.userRoles.includes("admin") ||
                    user.userRoles.includes("super_admin")) && (
                    <>
                      <Link
                        to="/admin"
                        className="flex text-gray-700 hover:text-gray-900 items-center gap-1"
                      >
                        <FaCog /> Admin
                      </Link>
                      <Link
                        to="/audit-logs"
                        className="flex text-gray-700 hover:text-gray-900 items-center gap-1"
                      >
                        <FaClipboard /> Audit Logs
                      </Link>
                    </>
                  )}
                <button
                  className="text-gray-700 flex items-center hover:opacity-20 gap-1"
                  onClick={onLogout}
                >
                  <FaSignOutAlt /> Logout
                </button>
              </>
            ) : (
              <>
                <Link to="/" className="text-gray-700 hover:text-gray-900">
                  Features
                </Link>
                <Link to="/" className="text-gray-700 hover:text-gray-900">
                  Pricing
                </Link>
                <Link to="/" className="text-gray-700 hover:text-gray-900">
                  Contact
                </Link>
              </>
            )}
          </div>
        </div>

        {/* Dropdown Menu for Small Screens */}
        {isOpen && (
          <div className="sm:hidden flex flex-col my-2 py-2 space-y-6">
            {user ? (
              <>
                <button className="text-gray-700 flex items-center hover:opacity-20">
                  <FaUser /> {user.name}
                </button>
                {user.userRoles &&
                  (user.userRoles.includes("admin") ||
                    user.userRoles.includes("super_admin")) && (
                    <>
                      <Link
                        to="/admin"
                        className="flex items-center gap-1 text-gray-700 hover:text-gray-900 "
                      >
                        <FaCog />
                        Admin
                      </Link>
                      <Link
                        to="/audit-logs"
                        className="flex items-center gap-1 text-gray-700 hover:text-gray-900 "
                      >
                        <FaClipboard />
                        Audit Logs
                      </Link>
                    </>
                  )}
                <button
                  className="text-gray-700 flex items-center hover:opacity-20"
                  onClick={onLogout}
                >
                  <FaSignOutAlt /> Logout
                </button>
              </>
            ) : (
              <>
                <Link to="/" className="text-gray-700 hover:text-gray-900">
                  Features
                </Link>
                <Link to="/" className="text-gray-700 hover:text-gray-900">
                  Pricing
                </Link>
                <Link to="/" className="text-gray-700 hover:text-gray-900">
                  Contact
                </Link>
              </>
            )}
          </div>
        )}
      </div>
    </nav>
  );
};

export default Header;
