import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import axios from "axios";

let firebaseConfig;
let messaging;

export const setupFirebase = async () => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/notifications/firebase-config`);
    firebaseConfig = response.data;

    const firebaseApp = initializeApp(firebaseConfig);
    messaging = getMessaging(firebaseApp);

    console.log("messaging obj",messaging);

    console.log("Firebase initialized.");
   // return messaging;
  } catch (error) {
    console.error("Error fetching Firebase config:", error);
  }
};


export { messaging,getMessaging, getToken, onMessage, firebaseConfig };
