import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getCheckLists } from "../features/checklist/checkListSlice";
import { createOrUpdateACheckListItem } from "../features/checklistItem/checkListItemSlice";
import Spinner from "../components/Spinner";
import { toast } from "react-toastify";
import "../index.css";

const ShareTargetHandler = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { checkLists, isLoading } = useSelector((state) => state.checkLists);
  const [sharedData, setSharedData] = useState("");

  useEffect(() => {
    if (location.pathname === "/share") {
      const urlParams = new URLSearchParams(location.search);
      const text = urlParams.get("text");

      // Set the shared data in state
      setSharedData(text);
    }
  }, [location]);

  useEffect(() => {
    dispatch(getCheckLists());
  }, [dispatch]);

  const handleCheckListClick = (checkListID) => {
    const newCheckListItem = {
      description: sharedData,
      status: "pending",
      checkListID,
    };

    dispatch(createOrUpdateACheckListItem(newCheckListItem))
      .unwrap()
      .then(() => {
        navigate("/");
      })
      .catch((error) => {
        toast.error(
          "Failed to create or update checklist item: " + error.message
        );
      });
  };

  return (
    <div className="flex flex-col items-center justify-center my-4">
      <h1 className="text-gray-600 font-semibold text-xl">Shared Content</h1>
      <textarea
        className="p-2 border mx-6 my-4 text-gray-600 w-4/5"
        value={sharedData}
      />
      <h1 className="my-10 text-gray-600 font-bold text-2xl px-4">
        Select a checklist to add the shared content
      </h1>
      {isLoading ? (
        <Spinner />
      ) : (
        <ul className="w-full">
          {checkLists.map((checkList, index) => (
            <React.Fragment key={checkList._id}>
              <li
                className="flex items-center justify-center  my-4"
                onClick={() => handleCheckListClick(checkList._id)}
                style={{ cursor: "pointer" }}
              >
                {checkList.name}
              </li>
              {index < checkLists.length - 1 && (
                <hr className="px-4 py-4 mx-10" />
              )}
            </React.Fragment>
          ))}
        </ul>
      )}
    </div>
  );
};

export default ShareTargetHandler;
