import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL 

// Fetch dashboard data
const fetchDashboardData = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const response = await axios.get(API_URL + `/user/dashboard?timezone=${userTimeZone}`, config)
  return response.data
}

// Fetch activities
const fetchActivities = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }
  const response = await axios.get(API_URL + '/user/focused-work/today', config)
  return response.data
}

// Fetch focused work
const fetchFocusedWork = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  console.log("User Timezone:", userTimeZone);
  const response = await axios.get(API_URL + `/user/focused-work/today?timezone=${userTimeZone}`, config)
  return response.data
}

// Create focused work
const createFocusedWork = async (focusedWorkData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }
  const response = await axios.post(API_URL + '/user/focused-work', focusedWorkData, config)
  return response.data
}

// Update focused work items
const updateFocusedWorkItems = async (focusedWorkData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }
  const response = await axios.put(API_URL + '/user/focused-work/update', focusedWorkData, config)
  return response.data
}

const dashboardService = {
  fetchDashboardData,
  fetchActivities,
  fetchFocusedWork, // Add fetchFocusedWork to the service
  createFocusedWork, // Add createFocusedWork to the service
  updateFocusedWorkItems, // Add updateFocusedWorkItems to the service
}

export default dashboardService
