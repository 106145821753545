import { getMessaging,messaging, getToken, onMessage, firebaseConfig, setupFirebase } from "../config/firebase_config";
import axios from "axios";
import { toast } from 'react-toastify';

await setupFirebase()

export const requestNotificationPermission = async (userId) => {
  try {
    //const messagingInstance = await setupFirebase(); // Initialize Firebase
    const permission = await Notification.requestPermission();
    if (permission === "granted") {
      console.log("Notification permission granted.");

      // ✅ Register Service Worker from frontend's origin (NOT backend)
      const registration = await navigator.serviceWorker.register("/firebase-messaging-sw.js");
      console.log("Service Worker registered:", registration);

      // ✅ Send Firebase config to Service Worker
      navigator.serviceWorker.ready.then((reg) => {
        console.log("Service Worker ready:", reg);
        console.log("Sending firebaseConfig to Service Worker:", firebaseConfig);
        reg.active.postMessage({ firebaseConfig });
      });
    
      const messaging = getMessaging();
      const token = await getToken(messaging, {
        vapidKey: process.env.FCM_PUBLIC_VAPID_KEY,
        serviceWorkerRegistration: registration,
      });

      if (token) {
        console.log("FCM Token:", token);
        
        await saveTokenToServer(userId, token);
      } else {
        console.log("Failed to get FCM token.");
      }

     
    } else {
      console.log("Notification permission denied.");
    }
  } catch (error) {
    console.error("Error getting notification permission:", error);
  }
};

const saveTokenToServer = async (userId, token) => {
  try {
    await axios.post(`${process.env.REACT_APP_API_URL}/user/notifications/update-fcm-token`, {
      userId,
      fcmToken: token,
    });
    console.log("FCM Token saved to server.");
    toast.success(
      <>
      FCM Token saved to server. {token.substring(0, 6)}...
      </>,
      
    );
  } catch (error) {
    console.error("Error saving FCM token:", error);
    toast.error("Error saving FCM token.");
  }
};


// onMessage(messaging, (payload) => {
//   console.log("📩 Foreground Notification Received:", payload);
  
//   // Show an alert or update UI
  
//   alert(`New Notification: ${payload.notification.title} - ${payload.notification.body}`);
  
// });

