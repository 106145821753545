import { configureStore } from '@reduxjs/toolkit';
import authReducer from '../features/auth/authSlice'
import checkListReducer from '../features/checklist/checkListSlice'
import  checkListItemsReducer  from '../features/checklistItem/checkListItemSlice';
import adminReducer from '../features/admin/adminSlice'
import dashboardReducer from '../features/dashboard/dashboardSlice';
import categoryReducer from '../features/category/categorySlice';
import goalMapReducer from '../features/goalmap/goalMapSlice'
import notificationReducer from '../features/notifications/notificationSlice'

export const store = configureStore({
  reducer: {
    auth: authReducer,
    checkLists: checkListReducer,
    checkListItems:checkListItemsReducer,
    admin: adminReducer,
    dashboard: dashboardReducer,
    categories: categoryReducer,
    goalMaps:goalMapReducer,
    notifications: notificationReducer,
  },
});
