import React from "react";
import { AiFillCloseCircle } from "react-icons/ai";

const Tag = ({ tag, onDelete, onClick, isSelected }) => {
  return (
    <span
      className=" flex flex-row items-center   text-xs px-3 py-1 rounded-full hover:bg-gray-200 cursor-pointer"
      style={{
        borderColor: tag.color === "#ffffff" ? "#1a6e08" : tag.color, // || "#fef4f6",
        color: "#646664",
        borderWidth: isSelected ? "4px" : "2px",
      }}
      onClick={() => onClick(tag)}
    >
      {tag.name}
      {onDelete && (
        <AiFillCloseCircle
          className="ml-1 cursor-pointer"
          onClick={(e) => {
            e.stopPropagation();
            onDelete(tag);
          }}
        />
      )}
    </span>
  );
};

export default Tag;
