import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL 

// Fetch user notifications
const fetchUserNotifications = async (token, page = 1) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }
  const response = await axios.get(`${API_URL}/user/notifications?page=${page}`, config)
  return response.data
}

const notificationService = {
  fetchUserNotifications,
}

export default notificationService
