import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Header from './components/Header'
import Home from './pages/Home'
import Login from './pages/Login'
import Register from './pages/Register'
import CheckList from './pages/CheckList'
import CheckLists from './pages/CheckLists'
import PrivateRoute from './components/PrivateRoute'
import PublicRoute from './components/PublicRoute'
import GoogleLogin from './components/GoogleLogin'
import ShareTargetHandler from './components/Share-Target'
import AdminDashboard from './pages/AdminDashboard'
import AuditLogs from './pages/AuditLogs'
import UserDashboard from './pages/UserDashboard'
import TagBar from './components/TagBar'

import GoalMapList from './pages/GoalMapList'
import GoalMapPage from './pages/GoalMapPage'

// NOTE: Here we have removed the nested routing as the path is the same

function App() {

  

  return (
    <>
      <Router>
        <div  className="h-screen md:h-[300px]">
          <Header />
              
          <Routes>
            <Route path='/' element={<PrivateRoute />} >
              <Route path='/' element={<UserDashboard />} />
              <Route path='/checklists' element={<CheckLists />} />
              <Route path='/admin' element={<AdminDashboard />} />
              <Route path='/audit-logs' element={<AuditLogs />} />
              <Route path="/checklists/:userId" element={<CheckLists />} />
              <Route path="/checklist/:id" element={<CheckLists />} />
              <Route path="/goal-maps" element={<GoalMapList />} />
              <Route path="/goal-map/create" element={<GoalMapPage />} />
              <Route path="/goal-map/:id" element={<GoalMapPage />} />
              <Route path="/share" element={<ShareTargetHandler />} />
            </Route>
            <Route path="/" element={<PublicRoute />}>
            <Route path='/login' element={<Login />} />
            <Route path='/register' element={<Register />} />
            <Route path='/google/auth/success' element={<GoogleLogin/>}/>
           </Route>

          </Routes>
        </div>
      </Router>
      <ToastContainer position="bottom-center" // Set the default position
        autoClose={500} />
    </>
  )
}

export default App
